import { Spinner } from 'components/elements';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as React from 'react';

const variants = {
  primary:
    'border-none bg-flamingo-500 dark:bg-flamingo-500 text-white hover:bg-flamingo-500 hover:text-white',
  inverse:
    'border-none bg-white text-flamingo-600 dark:text-white dark:bg-flamingo-700',
  danger: 'border-none bg-red-600 text-white hover:bg-red-50:text-red-600',
  outline:
    'bg-white dark:bg-dim-100 border text-flamingo-500 border-flamingo-500',
  darkoutline:
    'bg-white dark:bg-dim-100 dark:text-gray-100 border text-gray-500 border-gray-500',
};

const sizes = {
  xs: 'py-1 px-2 text-xs',
  sm: 'py-2 px-4 text-sm',
  md: 'py-2 px-6 text-md',
  lg: 'py-3 px-8 text-lg',
};

export const Button = React.forwardRef(
  (
    {
      type = 'button',
      className = '',
      variant = 'primary',
      size = 'md',
      isLoading = false,
      startIcon,
      endIcon,
      ...props
    },
    ref
  ) => {
    return (
      <div className="">
        <button
          ref={ref}
          type={type}
          className={clsx(
            'flex justify-center capitalize items-center rounded-md shadow-sm font-medium focus:outline-none',
            variants[variant],
            sizes[size],
            className
          )}
          {...props}
        >
          {isLoading && <Spinner size="sm" className="text-current" />}
          {!isLoading && startIcon}
          <span className="mx-2">{props.children}</span> {!isLoading && endIcon}
        </button>
      </div>
    );
  }
);

Button.displayName = 'Button';

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    'primary',
    'inverse',
    'danger',
    'outline',
    'darkoutline',
  ]),
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xs']),
  isLoading: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
};
