import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getPortfolios = () => {
  return axios.get('/tapp/portfolio/active_list/');
};

export const usePortfolios = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['portfolios'],
    queryFn: () => getPortfolios(),
  });
};
