// import { PlusIcon } from '@heroicons/react/outline';
import React from 'react';
import * as z from 'zod';
import { Form, FormDrawer, InputField } from 'components/form';
import { Button } from 'components/elements';
import { useCreatePortfolio } from '../api/createPortfolio';
import { useNavigate } from 'react-router-dom';

const schema = z.object({
  name: z.string().min(1, 'Required'),
});

export const CreatePortfolio = ({ title }) => {
  const createPortfolioMutation = useCreatePortfolio();

  const navigate = useNavigate();

  return (
    <>
      <FormDrawer
        size="sm"
        isDone={createPortfolioMutation.isSuccess}
        triggerButton={
          <Button
            className="w-full py-5"
            // startIcon={<PlusIcon className="h-4" />}
          >
            <span>{title}</span>
          </Button>
        }
        title="Create New Portfolio"
        submitButton={
          <Button
            form="create-portfolio"
            type="submit"
            size="sm"
            isLoading={createPortfolioMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="create-portfolio"
          onSubmit={async values => {
            await createPortfolioMutation.mutateAsync({ data: values });
            navigate('/app/portfolios/create/success');
          }}
          schema={schema}
        >
          {({ register, formState }) => (
            <>
              <InputField
                label="Portfolio Name"
                required={true}
                error={formState.errors['name']}
                registration={register('name')}
              />
            </>
          )}
        </Form>
      </FormDrawer>
    </>
  );
};
