import React, { useState } from 'react';
import logo from 'assets/logo-dark.svg';
import darklogo from 'assets/logo.svg';
import {
  FiActivity,
  FiAirplay,
  FiInfo,
  FiList,
  FiShoppingBag,
  FiXCircle,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from 'lib/auth';
import { useNotifications } from '../api/getNotifications';
import { ColorSwitch } from './ColorSwitch';
import { useColorModeContext } from 'context/ColorContext';
import { BiBell } from 'react-icons/bi';
import { SidebarFooter } from './SidebarFooter';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const SideBar = ({ page, sidebarOpen, setSidebarOpen }) => {
  const [active] = useState(page);

  const notificationsQuery = useNotifications();
  const { user } = useAuth();
  const darkMode = useColorModeContext();

  if (notificationsQuery.isLoading) {
    return <></>;
  }

  if (!notificationsQuery.data) {
    return null;
  }

  return (
    <div
      className="bg-accent-500 py-8 px-8 static dark:bg-dim-400 h-full flex flex-col 
    justify-between"
    >
      <div className="flex flex-row-reverse my-4 md:hidden">
        <button onClick={() => setSidebarOpen(!sidebarOpen)}>
          <FiXCircle className="h-8 w-auto" />
        </button>
      </div>
      <div>
        <Link to={user ? '/app' : '/'}>
          <img
            src={darkMode[0] ? darklogo : logo}
            alt="logo"
            className="h-8 w-auto mx-auto"
          />
        </Link>
        <div className="py-12 space-y-4">
          <Link
            to="/app"
            className={classNames(
              active === 'overview'
                ? 'bg-flamingo-500 text-white'
                : 'dark:text-gray-300',
              `flex items-center text-base space-x-4 py-3 px-10 
                 rounded-lg hover:bg-flamingo-500 hover:text-white`
            )}
          >
            <FiAirplay />
            <p>Overview</p>
          </Link>
          <Link
            to="/app/portfolios"
            className={classNames(
              active === 'portfolios'
                ? 'bg-flamingo-500 text-white'
                : 'dark:text-gray-300',
              `flex items-center text-base space-x-4 py-3 px-10 
                 rounded-lg hover:bg-flamingo-500 hover:text-white`
            )}
          >
            <FiActivity />
            <p>Portfolio</p>
          </Link>
          <Link
            to="/app/watchlist"
            className={classNames(
              active === 'watchlist'
                ? 'bg-flamingo-500 text-white'
                : 'dark:text-gray-300',
              `flex items-center text-base space-x-4 py-3 px-10 
                 rounded-lg hover:bg-flamingo-500 hover:text-white`
            )}
          >
            <FiList />
            <p>Watchlist</p>
          </Link>
          <Link
            to="/app/trades/logs"
            className={classNames(
              active === 'trades/logs'
                ? 'bg-flamingo-500 text-white'
                : 'dark:text-gray-300',
              `flex items-center text-base space-x-4 py-3 px-10 
                 rounded-lg hover:bg-flamingo-500 hover:text-white`
            )}
          >
            <FiShoppingBag />
            <p>Trade logs</p>
          </Link>
          <Link
            to="/app/notifications"
            className={classNames(
              active === 'notifications'
                ? 'bg-flamingo-500 text-white'
                : 'dark:text-gray-300',
              `flex items-center text-base space-x-4 py-3 px-10 
                 rounded-lg hover:bg-flamingo-500 hover:text-white`
            )}
          >
            <BiBell />
            <p className="flex flex-row items-center space-x-2">
              <span>Notifications</span>
            </p>
          </Link>
          <Link
            to="/app/support"
            className={classNames(
              active === 'support'
                ? 'bg-flamingo-500 text-white'
                : 'dark:text-gray-300',
              `flex items-center text-base space-x-4 py-3 px-10 
                 rounded-lg hover:bg-flamingo-500 hover:text-white`
            )}
          >
            <FiInfo />
            <p>Support</p>
          </Link>
        </div>
      </div>
      <SidebarFooter />
    </div>
  );
};

SideBar.propTypes = {
  page: PropTypes.string,
  sidebarOpen: PropTypes.string,
  setSidebarOpen: PropTypes.string,
};
