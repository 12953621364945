import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentLayout } from 'components/layout';

export const Search = () => {
  const [q, setQ] = useState('');
  const navigate = useNavigate();

  const handleInputChange = e => {
    setQ(e.target.value);
  };

  const handleSearch = () => {
    if (q.length === 0) {
      navigate(`/app`);
      return (
        <ContentLayout
          page="search"
          headerContentLeft={
            <div className="text-2xl font-semibold md:font-semibold">
              Search Results
            </div>
          }
        >
          <div className="bg-accent-500 shadow hover:shadow-lg py-3 rounded-md px-10">
            <div className="flex items-center gap-3 font-bold">No search query</div>
            <div>Please enter a search query to get results.</div>
          </div>
        </ContentLayout>
      );
    }
    // Navigate to the results page with the search query as a URL parameter
    const prompt = q.toLowerCase(); // Convert q to uppercase
    if (prompt.length === 0) navigate(`/app/`);
    if (!prompt) navigate(`/app`);
    navigate(`/app/search/tickers/${encodeURIComponent(prompt)}`);
  };

  return (
    <div>
      <form>
        <label
          htmlFor="search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
        >
          Search
        </label>
        <div
          className="border border-gray-300 flex rounded-lg bg-gray-50 w-42 md:w-72 
          focus:ring-0 focus:border-flamingo-500 dark:bg-dim-100 
              dark:border-gray-600 dark:placeholder-gray-400 dark:text-white 
              dark:focus:ring-flamingo-500 dark:focus:border-flamingo-500 flex items-center px-3"
        >
          <div className="pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            value={q}
            onChange={handleInputChange}
            type="search"
            id="search"
            className="block uppercase w-full p-4 pl-6 text-sm text-gray-900 dark:bg-dim-100
              outline-0 bg-gray-50 dark:text-gray-200"
            placeholder="Search"
            required
          />
          <button
            onClick={handleSearch}
            className="text-white bg-flamingo-500 hover:bg-flamingo-800 focus:ring-0 focus:outline-none focus:ring-flamingo-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-flamingo-600 dark:hover:bg-flamingo-700 dark:focus:ring-flamingo-800"
          >
            Search
          </button>
        </div>
      </form>
    </div>
  );
};
