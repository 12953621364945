import React, { useState, useEffect } from 'react';
import './pricechart.css';

export const PriceChart = ({ entryPrice, targetPrice, currentPrice, quantity }) => {
  // Calculate the current gain.
  const gain =
    quantity >= 0 ?
      ((currentPrice - entryPrice) / entryPrice) * 100:
      ((entryPrice - currentPrice) / entryPrice) * 100;
  
  // Calculate the percentage progress towards the target price.
  // Note that if the price is less than the target price, the progress
  // is equivalent to the gain i.e -ve
  const progress =
    gain >= 0 ?
      quantity >= 0 ?
        ((currentPrice - entryPrice) / (targetPrice - entryPrice)) * 100:
        ((entryPrice - currentPrice) / (entryPrice - targetPrice)) * 100: gain;

  // Define the colors for the lines and zones
  const entryColor = '#804E4E';
  const targetColor = '#48856F';
  const currentColor = '#E8D39B';
  const archivedTargetColor = '#48856F';
  const redZoneColor = '#FE9F9F';
  const greenZoneColor = '#A8E4B1'; // Light shade of green
  const blueZoneColor = '#E3F0FF'; // Light shade of blue

  // Define the chart's width and height
  const chartWidth = 100;
  const chartHeight = 16; // Increased height to accommodate the arrow and caption

  // Calculate the x-coordinate for the entry price line
  const entryX = quantity >= 0 ? chartWidth / 4: chartWidth * 3 / 4;

  // Calculate the x-coordinate for the target price line
  const targetX = quantity >= 0 ? chartWidth * 3 / 4: chartWidth / 4;

  // Calculate the x-coordinate for the current price line
  let currentX =
    progress <= -100 ? 0:
    progress < -10 ? entryX * ( 0.5 + (progress + 10) / 180) :
    progress < 0 ? entryX * ( 1 + 5 * progress / 100) :
    progress <= 100 ? entryX + (targetX - entryX) * (progress / 100) :
    progress < 200 ? targetX + entryX * (progress - 100) / 100 : chartWidth;

  // Adjust currentX calculation for negative quantity (entryX > targetX)
  if (quantity < 0) {
    currentX =
      progress > 200 
        ? 0
        : progress > 100
        ? targetX - (progress - 100) / 100 * targetX 
        : progress > 0
        ? entryX - (entryX - targetX) * progress / 100
        : progress > - 10
        ? entryX - 5 * progress / 100 * targetX
        : progress > -100
        ? entryX + (0.5 + (progress + 10) / 180) * targetX
        : chartWidth;
  }

  // Calculate the y-coordinate for the star in the middle of the line
  const starY = chartHeight / 2;

  // State to control blinking visibility for star and arrow
  const [isBlinking, setIsBlinking] = useState(true);

  // Toggle the blinking of star and arrow every 1 second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsBlinking(prevIsBlinking => !prevIsBlinking);
    }, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Check if the target price has been achieved
  const isTargetAchieved = currentPrice === targetPrice;
  const direction = quantity > 0 ? 1: -1;
  const redX = quantity > 0 ? currentX: entryX;
  const blueX = quantity > 0 ? entryX: currentX;
  const greenX = quantity > 0 ? targetX: currentX;

  return (
    <div
      className={`border border-gray-200 rounded-full shadow hover:bg-gray-200 ${
        isTargetAchieved ? 'blinking-box' : ''
      }`}
    >
      <svg width={chartWidth} height={chartHeight}>
        {/* Red Zone (between entry price and current price) */}
        { progress < 0 && (
          <rect
            x={redX}
            y="0"
            width={direction * (entryX - currentX)}
            height={chartHeight}
            fill={redZoneColor}
          />
        )}

        {/* Blue Zone (between entry price and target price) */}
        { progress >= 0 && progress <= 100 && (
          <rect
            x={blueX}
            y="0"
            width={direction * (currentX - entryX)}
            height={chartHeight}
            fill={blueZoneColor}
          />
        )}

        {/* Green Zone (after target price) */}
        { progress > 100 && (
          <rect
            x={greenX}
            y="0"
            width={direction * (currentX - targetX)}
            height={chartHeight}
            fill={greenZoneColor}
          />
        )}

        {/* Entry Price Line */}
        <line
          x1={entryX}
          y1="0"
          x2={entryX}
          y2={chartHeight}
          stroke={entryColor}
          strokeWidth="2"
        />

        {/* Target Price Line */}
        <line
          x1={targetX}
          y1="0"
          x2={targetX}
          y2={chartHeight}
          stroke={isTargetAchieved ? archivedTargetColor : targetColor}
          strokeWidth="2"
        />

        {/* Arrow */}
        <polygon
          points={`${targetX - 5},0 ${targetX},${chartHeight - 14} ${
            targetX + 5
          },0`}
          fill={isTargetAchieved ? archivedTargetColor : targetColor}
          stroke="#fff"
          strokeWidth="1"
          style={{
            animation: isBlinking ? 'blink-animation 1s infinite' : 'none',
          }}
        />

        {/* Current Price Line */}
        <line
          x1={currentX}
          y1="0"
          x2={currentX}
          y2={chartHeight}
          stroke={currentColor}
          strokeWidth="2"
        />

        {/* Blinking Star */}
        {isBlinking && (
          <polygon
            points={`${currentX - 5},${starY} ${currentX},${starY - 5} ${
              currentX + 5
            },${starY} ${currentX},${starY + 5}`}
            fill={currentColor}
            stroke="#fff"
            strokeWidth="1"
            style={{
              animation: 'blink-animation 1s infinite',
            }}
          />
        )}
      </svg>
      {/* Display caption if target price is achieved */}
      {isTargetAchieved && (
        <div className="caption text-xs">Archived target</div>
      )}
    </div>
  );
};
